//  Retrieving API key from env file
const API_KEY =
  "fd_key_27f99e61419b4639b2366d9cb526fde2.tRM7pvrCiKSEZqBgmgXfcR6cTijBw5sJTlFypLeUUnlUZZHm7lZEINsKpMSVa4vZi2Z9B41nosGJdvSzAFWJZV42OJkuBe4qWy3VY4QDPD1c2DKw64UzpnDe89lctEHGXDl1uJ3dNi8bYP3xUFemWphfUMbX5PJCEcVdpD4kg7HIquZKTkT3dEGe4zVHfspe";

// Function for submitting user data to flodesk
async function submitToFlodesk(firstName, lastName, email, phoneNumber, company) {
  const API_ENDPOINT = "https://api.flodesk.com/v1/subscribers";

  const data = {
    first_name: firstName,
    last_name: lastName,
    email,
    phone: phoneNumber,
    company_name: company,
    segment: "waitlist",
  };

  try {
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${API_KEY}`,
        "User-Agent": "Appnigma",
      },

      // Passing the data in body by spreadng the data object and passing the phone number and company name in custom fields
      body: JSON.stringify({
        ...data,
        custom_fields: {
          phone: phoneNumber,
          company: company,
        },
      }),
    });

    // Handling errors if the data is not submitted
    if (!response.ok) {
      throw new Error("Failed to submit data to Flodesk");
    }
    if (response.ok) {
      postToSegment(data.email);
    }

    // Fetching the response and logging the output
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw error;
  }
}

async function postToSegment(email) {
  const API_ENDPOINT = `https://api.flodesk.com/v1/subscribers/${email}/segments`;

  try {
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${API_KEY}`,
        "User-Agent": "Appnigma",
      },

      // Passing the data in body by spreadng the data object and passing the phone number and company name in custom fields
      body: JSON.stringify({
        segment_ids: ["6622b9685108521ad40749e0"],
      }),
    });

    // Handling errors if the data is not submitted
    if (!response.ok) {
      throw new Error("Failed to submit data to Flodesk");
    }

    // Fetching the response and logging the output
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    throw error;
  }
}

export { submitToFlodesk };
